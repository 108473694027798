<template>
    <settingsPagesLayout>
        <div class="p-inside-manage">
            <div class="page_title">Notifications</div>
            <section class="wrap_s_block">
                <div class="title_section">
                    <h2 class="name_section">Notifications</h2>
                </div>
                <div class="body_ privacy">
                    <h4 class="under-construction">In development</h4>
                </div>
            </section>
        </div>
    </settingsPagesLayout>
</template>

<style lang="scss">

.under-construction {
    text-align: center;
    font-size: 24px;
    color: rgba(95, 105, 134, 0.67);
    margin: 30px 0;

    @media (max-width: 480px) {
        font-size: 20px;
        margin: 20px 0;
    }
}
</style>

<script>
import settingsPagesLayout from "@/layouts/settingsPagesLayout";

export default {
    name: 'notifications',
    components: {
        settingsPagesLayout
    },

}
</script>
